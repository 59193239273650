/* eslint-disable */
<template>
  <v-container class="fntp-container" id="carbon-emmision" fluid tag="section">

    <v-row class="top-header-flex" style="justify-content: center;">

      <div style="width:80%;display:flex">
        <v-col>
          <a href="https://acteurspourlaplanete.fntp.fr/">
            <v-img
                    id="fntp-image"
                    height="60"
                    width="159"
                    src="/icons/svg/fntp-logo.svg"
            >
            </v-img>
          </a>
        </v-col>

      </div>
    </v-row>
    <v-row class="bottom-header-flex" style="height:144px;" >
      <span style="font-family: Dosis, sans-serif;
          font-size: 46px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.22;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff;
          text-transform: uppercase;
          align-self: center;
          padding-left: 80px;
          z-index:1;
      ">Calculateur CO<sub>2</sub> - Omega TP</span>
      <div style="position: absolute; right: 0px;">
        <v-img
                height="199"
                width="350"
                src="/icons/svg/dragees_titre.svg"
        >
        </v-img>
      </div>
    </v-row>


    <!-- astuce pour résoudre problème graphique d'update -->
    <div style="display:none" v-if="update"></div>

    <v-dialog
            v-model="dialog_load"
            width="1000"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <h2 class="mb-4 font-weight-bold">Attention</h2>
        </v-card-title>

        <v-card-text>
          Certaines de vos données n’ont pas pu être affichées en raison d’une mise à jour dans les facteurs d'émissions associés au calculateur.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
                  color="primary"
                  text
          @click="dialog_load = false;"
          >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-row style="justify-content: center;">
    <div class="main_prez" style="width:80%;max-width:1100px">

      <v-card style="padding:20px">


      <div>
        <v-btn
                class="bouton"
                text
        @click="$router.push({ name: 'FNTP' })"
        >
        Retour au Calculateur
      </v-btn>
    </div>

    <!-- Graphique -->
    <div style="text-align:center" class="text-moyen" ><u>Répartition de mes postes d'émissions</u></div>

    <br/>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="6"><canvas id="myChart" width="800" height="400"></canvas></v-col>
    </v-row>
    <br/>
    <br/>

    <!-- REDUCTIONS -->
    <div style="text-align:center" class="text-moyen" ><u>Mes actions de réduction personnalisées</u></div>


    <div v-for="reduc in reduction" >
        <v-card style="border: solid 1px">
          <v-row>
            <v-col cols="8">
              <div style="padding:20px">
                <div class="text-moyen text_bleu"><b>{{ reduc.titre }}</b></div>
                <div>{{ reduc.texte }}</div>
              </div>
            </v-col>
            <v-col cols="4" style="text-align:left">
              <div style="padding:20px">
                <div style="display:flex">
                <div class="text-moyen text_bleu"><b>Votre engagement</b>&nbsp;&nbsp;</div>
                <div class="text-moyen" style="text-align:right">{{ implication[reduc.engagement] }}</div>
                </div>
                <div><v-slider
                        v-model="reduc.engagement"
                        track-color="#6b6b6b"
                        color="#0a14b4"
                        :max="4"
                        step="1"
                        ticks="always"
                        tick-size="4"
                        @change="calculTotal()"
                ></v-slider></div>
                <div style="margin-top:-25px;text-align:center" class="text-moyen">-{{ reduc.potentiel * ((reduc.engagement)/4) }}t</div>
              </div>
            </v-col>
          </v-row>
          <br/>
          <v-row>
            <v-col cols="4" style="padding-top:0px;text-align:center">
              <span class="text-moyen text_bleu"><b>Potentiel de réduction</b></span>
              <div>
                <div style="display:flex;justify-content:center;" class="text-moyen"><div>{{reduc.potentiel}}{{reduc.unite}}</div>&nbsp;<div>({{reduc.percent}}%)</div></div>
              </div>
            </v-col>
            <v-col cols="4" style="padding-top:0px;text-align:center;border-right: solid 1px;border-left: solid 1px">
              <span class="text-moyen text_bleu"><b>Niveau de faisabilité</b></span>
              <div>
                <v-icon style="color:#14ebdc" v-if="reduc.faisabilite > 0">mdi-star</v-icon><v-icon style="color:#cbd2e0" v-else>mdi-star</v-icon>
                <v-icon style="color:#14ebdc" v-if="reduc.faisabilite > 1">mdi-star</v-icon><v-icon style="color:#cbd2e0" v-else>mdi-star</v-icon>
                <v-icon style="color:#14ebdc" v-if="reduc.faisabilite > 2">mdi-star</v-icon><v-icon style="color:#cbd2e0" v-else>mdi-star</v-icon>
                <v-icon style="color:#14ebdc" v-if="reduc.faisabilite > 3">mdi-star</v-icon><v-icon style="color:#cbd2e0" v-else>mdi-star</v-icon>
                <v-icon style="color:#14ebdc" v-if="reduc.faisabilite > 4">mdi-star</v-icon><v-icon style="color:#cbd2e0" v-else>mdi-star</v-icon>
              </div>
            </v-col>
            <v-col cols="4" style="padding-top:0px;text-align:center">
              <span class="text-moyen text_bleu"><b>Indicateurs de suivi</b></span>
              <div>{{reduc.suivi}}</div></v-col>
          </v-row>
          <v-row><v-col style="text-align:center" ><v-btn
                  class="bouton"
                  text
            @click="$router.push({ name: 'FNTP' })"
            >
            En savoir plus
          </v-btn></v-col></v-row>
          <br/>

        </v-card>
        <br/>
    </div>




<v-row>
<v-col>
  <div style="padding-top:10px" class="text-moyen">
    Total de réduction {{ total_reduction }}t ({{ total_percent }}%)
  </div>
</v-col>
<v-col style="text-align:right">
<v-btn
        class="bouton"
        text
@click="dialog_entreprise = true;"
>
Exporter
</v-btn>
        </v-col>
        </v-row>




      </v-card>

    </div>
  </v-row>

</v-container>
        </template>
<script>

  import { Doughnut } from 'vue-chartjs';

  import calculator_mixin from "./mixins/calculator_mixin";
  import Entete from "./Entete.vue"
  import axios from "axios";
  import VueHtml2pdf from 'vue-html2pdf'

  import Router from 'vue-router'

  const optionsChartOOH = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      animateRotate: false,
    },
  }



  export default {
    name: 'CarbonCalculator',
    components: {
      'entete' : Entete
    },
    mixins: [calculator_mixin],
    data() {
      return {

        implication:['Aucun','Faible','Moyen','Fort','Prioritaire'],

        total_reduction:'0',
        total_percent:'0',

        reduction:[
          {
            'titre':"Utiliser des matériaux alternatifs en génie civil",
            'texte':"Les matériaux alternatifs sont issus de gisements de déchets connus que sont les déchets de chantiers du BTP et les déchets de l’industrie et peuvent être utilisés en projet de construction ou d'aménagement en substitution de matières premières naturelles.",
            'suivi':"Tonnes de matérieux alternatifs utilisés par an",
            'potentiel':21,'unite':'t','percent':15,'faisabilite':3,'engagement':0
          },
          {
            'titre':"Diminuer l'empreinte carbone des enrobés",
            'texte':"Plusieurs leviers permettent de réduire l’empreinte carbone des enrobés bitumineux : l'utilisation d'agrégats d'enrobés recyclés, l'abaissement des températures de fabrication, l'utilisation d'enrobés à l'émulsion.",
            'suivi':"Émissions par tonne annuelles",
            'potentiel':17,'unite':'t','percent':6,'faisabilite':1,'engagement':0
          },
        ],

        legendGraph : {
          id : 'legendGraph',
          afterDraw(chart, args, options) {
            const { ctx, chartArea:{ top, bottom, left, right, width, height } } = charts;

            console.log("CHARTS : " + charts);
          }
        },

        graph: {
          type:'doughnut',
          data: {
            labels: ['Combustibles','Matériaux','Mobilités','Autres'],
            datasets: [{
              label: 'My First Dataset',
              data: [
                  this.$parent.emissions[0].valeur,
                  this.$parent.emissions[1].valeur,
                  this.$parent.emissions[2].valeur,
                  this.$parent.emissions[3].valeur
              ],
              backgroundColor: [
                '#14ebdc',
                '#858ad9',
                '#008cf0',
                '#0a14b4'
              ],
              hoverOffset: 4
            }]
          },
          options:{
            maintainAspectRatio: true,
            layout: {
              padding: 20
            },
            cutoutPercentage:'85',
            legend:{
                display: false
            }
          },
          plugins:
          [
            {
              id : 'legendGraph',
              afterDraw(chart, args, options)
              {
                const { ctx, chartArea } = chart;
                chart.data.datasets.forEach( (dSet, i) => {
                  chart.getDatasetMeta(i).data.forEach( (pt, i_pt) => {

                    if ( dSet.data[i_pt] > 0 )
                    {
                      const { x, y } = pt.tooltipPosition();

                      const midW = chart.width/2.0;
                      const midH = chart.height/2.0;

                      const xl = (x > midW) ? x+15 : x-15 ;
                      const yl = (y > midH)? y+15 : y-15 ;
                      const ll = (x > midW) ? 15 : -15 ;

                      ctx.fillStyle = dSet.backgroundColor[i_pt];
                      ctx.beginPath();
                      ctx.moveTo(x, y);
                      ctx.lineTo(xl, yl);
                      ctx.lineTo(xl+ll, yl);
                      ctx.strokeStyle = dSet.backgroundColor[i_pt];
                      ctx.stroke();

                      let posi =  (x > midW) ? "left" : "right"
                      ctx.textAlign = posi;
                      ctx.textStyle = dSet.backgroundColor[i_pt];
                      ctx.fillText( chart.data.labels[i_pt], xl+ll, yl );
                    }


                  } );
                } );
              }
            }
          ]
        },

        date_fe:'',
        date_fe_maj:'',
        entreprise:{ nom:'',
          siren:'',
          adresse_rue:'',
          adresse_CP:'',
          adresse_ville:''
        },
        dialog_entreprise: false,
        dialog_load: false,
        update:false,
        pdf_completion_page:0,
        grandTotal:{
            index:0,
            label:'Total',
            valeur:0,
            unite:'kgCO2',
        },
        totaux:[
          {
            index:0,
            label:'Combustibles',
            valeur:0,
            unite:'kgCO2',
          },
          {
            index:1,
            label:'Matériaux',
            valeur:0,
            unite:'kgCO2',
          },
          {
            index:2,
            label:'Mobilités',
            valeur:0,
            unite:'kgCO2',
          },
          {
            index:3,
            label:'Autres',
            valeur:0,
            unite:'kgCO2',
          },
        ],
        panels: [],
        mes_postes: [
          {
            nom:'Electricité',
            valeur:'',
          }
        ],
        mes_ressources: [
          {
            index:0,
            id:'Combustion-Electricité',
            saisie:'',
            scope:1,
            poste:'Electricité',
            valeur:'',
            unite:'kWh',
            nom:'Combustion',
            conversion:0.0395,
          }
        ],
        mes_scopes:[],
      }
    },
    computed: {},

    mounted() {
      window.scrollTo(0, 0);

      const ctx = document.getElementById('myChart');
      const myChart = new Chart(ctx, this.graph);

      //this.LoadValeurCookie();
    },
    methods: {

     calculTotal(){

      let divide = this.implication.length-1;

      this.total_reduction = 0;
      this.total_percent = 0;
      this.reduction.forEach( reduc => {
        this.total_reduction += reduc.potentiel * (reduc.engagement/divide);
        this.total_percent += reduc.percent * (reduc.engagement/divide);
      });
     },

     setCookie(name,value,days) {
          let expires = "";
          if (!days)
          {
            days = 700;
          }

          let date = new Date();
          date.setTime(date.getTime() + (days*24*60*60*1000));
          expires = "; expires=" + date.toUTCString();

          document.cookie = name + "=" + (value || "")  + expires + "; path=/";
      },

     getCookie(name) {
          let nameEQ = name + "=";
          let ca = document.cookie.split(';');
          for(let i=0;i < ca.length;i++) {

              console.log( ca[i] )
              let c = ca[i];
              while (c.charAt(0)==' ') c = c.substring(1,c.length);
              if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);

          }
          return null;
      },


      set_cookie_entreprise()
      {
        let str_cook = '{"nom":"' + this.entreprise.nom + '"' ;
        str_cook = str_cook + ', "siren":"' + this.entreprise.siren + '"'  ;
        str_cook = str_cook + ', "adresse_rue":"' + this.entreprise.adresse_rue + '"'  ;
        str_cook = str_cook + ', "adresse_CP":"' + this.entreprise.adresse_CP + '"'  ;
        str_cook = str_cook + ', "adresse_ville":"' + this.entreprise.adresse_ville + '"}' ;

        this.setCookie('entreprise',str_cook);
      },


      LoadValeurCookie()
      {

        let cook_str = this.getCookie('entreprise');
        if ( cook_str != null )
        {
          this.entreprise = JSON.parse(cook_str);
        }

        cook_str = this.getCookie('mes_postes');
        let cook_postes = JSON.parse(cook_str);

        cook_postes.forEach( poste => {

          cook_str = this.getCookie(poste);
          let cook_poste = JSON.parse(cook_str);


          cook_poste.forEach( ress_cook => {

            let corespondance = 0;

            this.mes_ressources.forEach( ress => {

              if (ress.poste == poste)
              {
                if( ress.id == ress_cook.id  && ress_cook.saisie != "")
                {
                  corespondance = 1;
                  this.mes_ressources[ress.index].saisie = ress_cook.saisie;
                  this.caculCARB(ress.index,false);
                }
              }
            });

            if( corespondance == 0 && ress_cook.saisie != "" )
            {
              this.dialog_load = true;
            }

          });

        });
      },

      EffaceSaisie()
      {
        this.mes_ressources.forEach( elem => { elem.valeur = 0; elem.affichage = ''; elem.saisie = '' });
        this.mes_postes.forEach( elem =>
        {
            elem.valeur = 0; elem.affichage = '';
            elem.sousposte.forEach( sp_elem =>
            {
                sp_elem.valeur = 0; sp_elem.affichage = '';
            });
        });

        this.totaux.forEach( elem => { elem.valeur = 0;  elem.affichage = this.AfficheMeilleurUniteCARB(0) });
        this.grandTotal.valeur = 0;  this.grandTotal.affichage = this.AfficheMeilleurUniteCARB(0) ;
      },

      GenerateReport()
      {

            // % occupation taille de page du début du fichier ( avant les scope )
            let saut_ligne = 60
            saut_ligne = 0 // force Saut de ligne page de garde

            this.mes_scopes = [
              {'nom':'Scope 1', 'valeur':0, 'ressources':[], 'postes':[]},
              {'nom':'Scope 2', 'valeur':0, 'ressources':[], 'postes':[]},
              {'nom':'Scope 3', 'valeur':0, 'ressources':[], 'postes':[]}
            ];


            // il serait plus judiceux de parcourir les ressources,
            // puis pour chaque ressource parcourir les scope, mais cela rendrait le comptage d'occupation de la page
            // imposible à réaliser, nous sommes donc obligés de boucler dans l'ordre d'affichage des elements.
            for (let scopid in [0,1,2])
            {
              // % occupation taille du mot Scope + eventuelle ligne scope vide
              saut_ligne += 15;

              this.mes_ressources.forEach( elem =>{

                let float_elem_saisie = parseFloat( elem.saisie.replaceAll(' ','').replaceAll(',','.') );
                if(!isNaN(float_elem_saisie))
                {

                  if ( !isNaN(elem.conversion_scope[scopid]) )
                  {

                    let post_obj = {};
                    post_obj.nom = elem.poste;
                    post_obj.valeur = 0;
                    post_obj.affichage = '';
                    post_obj.sousposte=[];

                    if (elem.sousposte != '')
                    {
                      post_obj.sousposte.push(elem.sousposte)
                    }

                    let src_obj = {};
                    src_obj.nom = elem.nom;
                    src_obj.scope = elem.scope;
                    src_obj.poste = elem.poste;
                    src_obj.sousposte = elem.sousposte;
                    src_obj.saisie = elem.saisie;
                    src_obj.unite = elem.unite;

                    src_obj.valeur = '0';
                    src_obj.affichage = '';

                    src_obj.valeur =  float_elem_saisie * elem.conversion_scope[scopid];
                    this.mes_scopes[scopid].valeur += src_obj.valeur;

                    src_obj.affichage = this.AfficheMeilleurUniteCARB(src_obj.valeur);
                    this.mes_scopes[scopid].ressources.push(src_obj);

                    if(src_obj.affichage != '')
                    {
                      // % occupation taille de page d'une ligne
                      saut_ligne += 5;
                    }

                    let match_poste = false;
                    this.mes_scopes[scopid].postes.forEach( pelem =>{
                      if(pelem.nom == elem.poste)
                      {
                        pelem.valeur += src_obj.valeur;
                        if (elem.sousposte != '')
                        {
                          if( !pelem.sousposte.includes(elem.sousposte) )
                          {
                            pelem.sousposte.push(elem.sousposte);
                          }
                        }
                        pelem.affichage = this.AfficheMeilleurUniteCARB(pelem.valeur);
                        match_poste = true;
                      }
                    })
                    if(!match_poste)
                    {
                      post_obj.valeur = src_obj.valeur;
                      post_obj.affichage = this.AfficheMeilleurUniteCARB(post_obj.valeur);
                      this.mes_scopes[scopid].postes.push(post_obj);
                    }

                    let last_elem = this.mes_scopes[scopid].postes.length - 1;
                    if (saut_ligne > 100 && last_elem > -1)
                    {
                      this.mes_scopes[scopid].postes[last_elem].saut_de_ligne = true;
                      saut_ligne = saut_ligne - 100;

                      saut_ligne += 28; //entête
                    }


                  }
                }
              });

            }

            this.$refs.html2Pdf.generatePdf();

      },


      AfficheMeilleurUniteCARB(valeur)
      {
        if(valeur > 0)
        {
          let unite = 'kg';
          let val = valeur;

          if (valeur > 1000000000.0)
          {
            val = valeur / 1000000000.0;
            unite = 'Mt'
          }
          else if (valeur > 1000000.0)
          {
            val = valeur / 1000000.0;
            unite = 'kt'
          }
          else if (valeur > 1000.0)
          {
            val = valeur / 1000.0;
            unite = 't'
          }

          return ( this.formatAsNumber( ' '+Math.round(val * 100) / 100) ) + ' ' + unite ;
        }
        return '0 kg';
      },

      formatAsNumber(str)
      {
        // CODE NON COMPATIBLE AVEC SAFARI
        //return str.replace(/\./g, ',')  // on remplace les points par des virgules
        //.replace(/(?<=,.*),/g, '')    // on ne garde que la première virgule
        //.replace(/[^0-9,]/g,'')       // on ne garde que l'eventuelle virgule et les chiffres
        //.replace(/\B(?<!,\d*)(?=(\d{3})+(?!\d))/g,' ')  // on insert un espace tout les groupes de 3 chiffres avant la virgule

        let temp_val;
        let retour;
        temp_val = str.replace(/\./g, ',') // on remplace les points par des virgules
        .replace(/[^0-9,]/g,'') // on ne garde que les eventuelles virgules et les chiffres
        .split(',') // on decoupe par virgule

        // on travail sur la partie avant la virgule
        retour = temp_val[0];
        // on insert un espace tout les groupes de 3 chiffres
        let temp_ret = retour
        let len = temp_ret.length;
        if ( len > 3 )
        {
          retour = '';
          let grp = 3;
          for ( let i=len-1; i>-1; i--)
          {
            if ( grp == 0 )
            {
              retour = ' ' + retour;
              grp = 3;
            }
            retour = temp_ret[i] + retour;
            grp = grp - 1;
          }
        }

        // on rattache l'eventuelle partie après la virgule
        len = temp_val.length;
        if ( len > 1 )
        {
          retour = retour + ',';
          for ( let i=1; i<len; i++)
          {
            retour = retour + temp_val[i];
          }
        }
        return retour;
      },

      indiceDuTotal(nomTotal)
      {
          let len = this.totaux.length;
          for( let i=0; i<len; i++)
          {
            if ( this.totaux[i].label == nomTotal )
              return i;
          }
         return 3; // Autres par défault
      },

      caculCARB(id, setCookies=true)
      {
        // on reformat le champs
        this.mes_ressources[id].saisie = this.formatAsNumber(this.mes_ressources[id].saisie);
        // on extrait la valeur float/int du champs
        let saisie = parseFloat(this.mes_ressources[id].saisie.replaceAll(' ','').replaceAll(',','.'))

        if (saisie > 0)
        {
          let val = saisie * this.mes_ressources[id].conversion;
          this.mes_ressources[id].valeur = val
          this.mes_ressources[id].affichage = this.AfficheMeilleurUniteCARB( val );
        }
        else
        {
          this.mes_ressources[id].valeur = 0;
          this.mes_ressources[id].affichage = '';
        }


        let poste = this.mes_ressources[id].poste;
        let sousposte = this.mes_ressources[id].sousposte;
        if(!sousposte)
        {
          sousposte = '';
        }

        let total_poste = 0;
        let total_sousposte = 0;

        this.grandTotal.valeur = 0;

        this.totaux.forEach( tot => { tot.valeur = 0 ;} );

        this.mes_ressources.forEach(element =>
        {
          // evite d'attitioner des chiffres et des valeurs vide
          if( element.valeur > 0 )
          {
            this.grandTotal.valeur  += element.valeur;
            let indexTot = this.indiceDuTotal(element.scope);
            if ( indexTot > -1 )
            {
              this.totaux[indexTot].valeur += element.valeur;
            }
            if (element.poste == poste)
            {
              total_poste += element.valeur;
            }
            if (element.sousposte == sousposte && sousposte != '')
            {
              total_sousposte += element.valeur;
            }
          }
        });

        this.mes_postes.forEach(element =>
        {
           if (element.nom == poste)
           {
              element.valeur = total_poste;
              if (total_poste > 0)
                element.affichage = this.AfficheMeilleurUniteCARB(total_poste);
              else
                element.affichage = '';

              element.sousposte.forEach(elem => {

                 if (elem.nom == sousposte)
                 {
                    elem.valeur = total_sousposte;
                    if (total_sousposte > 0)
                      elem.affichage = this.AfficheMeilleurUniteCARB(total_sousposte);
                    else
                      elem.affichage = '';
                 }
              });


           }
        });


        this.grandTotal.affichage = this.AfficheMeilleurUniteCARB(this.grandTotal.valeur);
        this.totaux.forEach( tot => { tot.affichage = this.AfficheMeilleurUniteCARB(tot.valeur); } );


        if(setCookies)
        {
          let mes_cookie = "[";
          let first_post = 1;
          this.mes_postes.forEach( pos => {
            if (first_post == 0)
            {
              mes_cookie = mes_cookie + ',' ;
            }
            first_post = 0;
            mes_cookie = mes_cookie + '"' + pos.nom + '"';

            let mon_cookie = "[";
            let first_ress = 1;
            this.mes_ressources.forEach( ress => {

              if ( ress.poste == pos.nom )
              {
                if (first_ress == 0)
                {
                  mon_cookie = mon_cookie + ',' ;
                }
                first_ress = 0;
                mon_cookie = mon_cookie + '{"id": "'+ress.id+'", "saisie": "'+ress.saisie+'"}';
              }

            });
            mon_cookie = mon_cookie + "]";
            this.setCookie(pos.nom, mon_cookie);

          });
          mes_cookie = mes_cookie + "]";
          this.setCookie("mes_postes", mes_cookie);
        }

      },
    },
  }
</script>
<style lang="scss">

.gros-text-PDF
{
  font-size:1.5rem
}
.text-moyen-PDF
{
  font-size:1.2rem
}
.text-normal-PDF
{
  font-size:1rem
}


.gros-text
{
  font-size:1.5rem
}
@media screen and (max-width: 900px) {
  .gros-text
  {
    font-size:1.2rem
  }
}
@media screen and (max-width: 600px) {
  .gros-text
  {
    font-size:1rem
  }
}


.text-moyen
{
  font-size:1.4rem
}
@media screen and (max-width: 900px) {
  .text-moyen
  {
    font-size:1.1rem
  }
}
@media screen and (max-width: 600px) {
  .text-moyen
  {
    font-size:0.90rem
  }
}


.text-normal
{
  font-size:1.3rem
}
@media screen and (max-width: 900px) {
  .text-normal
  {
    font-size:1rem
  }
}
@media screen and (max-width: 600px) {
  .text-normal
  {
    font-size:0.75rem
  }
}


@media screen and (max-width: 800px) {
  .main_prez {
    width:100% !important;
    max-width:100% !important;
  }
}


.bouton
{
  border-radius: 100px !important;
  color: #0a14b4 !important;
  border-color: #0a14b4 !important;
  margin: 5px 5px 5px 5px;
  border: 1px solid;
  font-size: 1.2rem !important;
  text-transform: none !important;
}
.bouton:hover
{
  background-color: #0a14b4!important;
  color: #FFFFFF !important;
}


.bouton_bleu
{
  background-color: #0a14b4!important;
  color: #FFFFFF !important;
  border-radius: 100px !important;
  border-color: #0a14b4 !important;
  margin: 5px 5px 5px 5px;
  border: 1px solid;
  font-size: 1.2rem !important;
  text-transform: none !important;
}
.bouton_bleu:hover
{
  background-color: #FFFFFF!important;
  color: #0a14b4 !important;
}



.bandeau_total
{
  border-radius: 20px 20px 20px 20px !important;
  width: 100%;
  margin: 5px 0px 5px 0px;
  background-color: #0a14b4 !important;
  color : #FFFFFF !important;
}
.bandeau_poste
{
  border-radius: 20px 20px 20px 20px !important;
  width: 100%;
  margin: 5px 0px 5px 0px;
  background-color: #14ebdc !important;
  color : #FFFFFF !important;
}


.div_total
{
  border-radius: 10px 10px 10px 10px !important;
  background-color: #0a14b4 !important;
  color : #FFFFFF !important;
  margin-bottom : 15px;
  font-family: Dosis, sans-serif !important;

}
.div_poste
{
  border-radius: 10px 10px 10px 10px !important;
  background-color: #14ebdc !important;
  color : #FFFFFF !important;
  margin-bottom : 15px;
  font-family: Dosis, sans-serif !important;
}

.total
{
  background-color: #0a14b4 !important;
  color : #FFFFFF !important;
}

.panel
{
  border-radius: 10px 10px 10px 10px !important;
  background-color:  #FFFFFF;
  color: #384B6F !important;
}

.panel_content
{
  color: #384B6F !important;
  border-radius: 10px 10px 10px 10px !important;
  background-color:  #008cf088;
}

.panel_sousposte_head
{
  color: #384B6F !important;
  border-radius: 10px 10px 10px 10px !important;
  background-color:  #FFFFFF33 !important;
  margin:5px;
  padding:5px;
}

.panel_sousposte
{
  color: #384B6F !important;
  border-radius: 10px 10px 10px 10px !important;
  background-color:  #12D8CA !important;
  margin: 0px 5px 10px 5px;
  width: 100%
}

.panel_saisie
{
  color: #384B6F !important;
  border-radius: 10px 10px 10px 10px !important;
  background-color:  #12D8CA !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.panel_saisie_sp
{
  color: #0a14b4 !important;
  border-radius: 10px 10px 10px 10px !important;
  background-color:  #14ebdc33 !important;
}


.scope_PDF
{
  font-family: Dosis, sans-serif !important;
  font-size:3rem;
}

.text_bleu
{
  color: #0a14b4 !important;
}


.panel_PDF
{
  color: #0a14b4 !important;
  border: 1px solid;
  border-color: #0a14b4 !important;
  border-radius: 10px 10px 10px 10px !important;
  margin: 0px 20px 20px 0px;
}

.trait_PDF
{
  width: 100%;
  padding: 2px;
  border-bottom: 1px solid;
  border-color: #0a14b4 !important;
}

.saisie
{
  background-color:  #FFFFFF88 !important;
}

.top-header-flex {
  justify-content: center;
  background-color: #FFFFFF;
}

.bottom-header-flex {
  background-color: #0a14b4;
}

.titre-page {
  margin-top: 15px;
}
.legende {
  font-size : 12px ;
  display : block ;
}
.fntp-div {
  margin-top: 8px;
  margin-right: 5px;
}
.fntp-container {
  padding-left:   0px !important;
  padding-right:  0px !important;
}

.rotate_chevron_down {
  transition: transform 0.5s;
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  }

.rotate_chevron_up {
  transition: transform 0.5s;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  }


.v-expansion-panel::after
{
  border-radius: 10px !important;
  padding: 10px !important;
}

</style>
